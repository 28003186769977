import React from "react";
import { NavLink } from "react-bootstrap";

const Bdata=(props) => {
    return(<>
            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <article>
                    <NavLink to={props.links}>
                        <img className="rounded w-100" src={props.imgsrc} height="160" alt="test" />
                    </NavLink>
                    <div className="mt-2">
                        <time className="text-muted small d-block mb-1" datetime="2022-02-14 00:00"> <i className="bi-calendar"></i> {props.date}</time>
                        <NavLink to={props.links}>
                            <h5 className="title">{props.title}</h5>
                        </NavLink>
                        <p>{props.content}</p>
                    </div>
                </article>
            </div>
    </>)
};
export default Bdata;