import React from "react";

function Footer() {
    return(<>
    
    <footer class="section-footer bg-white shadow">
        <div class="container">
            <section class="footer-main py-5">
                <div class="row">
                    <aside class="col-lg-4">
                        <article class="me-lg-4 mb-4"> <img src="../img/logo.png" alt="" class="logo-footer" />
                            <p class="mt-3"> You might remember the Lenovo computer commercials in which a youth reports
                                this exciting news to his friends. </p>
                            <nav>
                                <a class="btn btn-icon btn-light" title="Facebook" target="_blank" href="/"><i class="bi-facebook"></i></a>
                                <a class="btn btn-icon btn-light" title="Instagram" target="_blank" href="/"><i class="bi-instagram"></i></a>
                                <a class="btn btn-icon btn-light" title="Youtube" target="_blank" href="/"><i class="bi-youtube"></i></a>
                                <a class="btn btn-icon btn-light" title="Twitter" target="_blank" href="/"><i class="bi-twitter"></i></a>
                            </nav>
                        </article>
                    </aside>
                    <aside class="col-6 col-sm-3 col-lg-2">
                        <h6 class="title">About</h6>
                        <ul class="list-menu mb-4">
                            <li> <a href="/about">About us</a></li>
                            <li> <a href="/service">Services</a></li>
                            <li> <a href="/terms">Rules and terms</a></li>
                            <li> <a href="/blog">Blogs</a></li>
                        </ul>
                    </aside>
                    <aside class="col-6 col-sm-3 col-lg-2">
                        <h6 class="title">Services</h6>
                        <ul class="list-menu mb-4">
                            <li> <a href="/about">About us</a></li>
                            <li> <a href="/service">Services</a></li>
                            <li> <a href="/terms">Rules and terms</a></li>
                            <li> <a href="/blog">Blogs</a></li>
                        </ul>
                    </aside>
                    <aside class="col-6 col-sm-3 col-lg-2">
                        <h6 class="title">For users</h6>
                        <ul class="list-menu mb-4">
                            <li> <a href="/about">About us</a></li>
                            <li> <a href="/service">Services</a></li>
                            <li> <a href="/terms">Rules and terms</a></li>
                            <li> <a href="/blog">Blogs</a></li>
                        </ul>
                    </aside>
                    <aside class="col-6 col-sm-2 col-lg-2">
                        <h6 class="title">Get the app</h6>
                        <a href="/" class="d-block mb-2"><img src="../img/btn-appstore.png" alt="" height="40" /></a>
                        <a href="/" class="d-block mb-2"><img src="../img/btn-market.png" alt="" height="40" /></a>
                    </aside>
                </div>
            </section>
            <section class="footer-bottom d-flex justify-content-lg-between border-top">
                <p class="text-muted mb-0"> © 2021 Templatemount. All rights reserved. </p>
                <nav>
                    <a href="/" class="px-2">Support</a>
                    <a href="/" class="px-2">Privacy &amp; Cookies</a>
                    <a href="/" class="px-2">Accessibility</a>
                </nav>
            </section>
        </div>
    </footer>

    </>)
};
export default Footer;