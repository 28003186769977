import React from "react";
import { NavLink } from "react-router-dom";

function Navbar() {
    return(<>

        <header className="section-header">
            <section className="header-main border-bottom">
                <div className="container">
                    <div className="row g-3 align-items-center">
                        <div className="col-lg-2 col-sm-3 col-4"> <NavLink to="/ " className="brand-wrap"> <img className="logo" height="40" src="img/logo.png" alt="logo" /> </NavLink>
                        </div>
                        <div className="col-lg-5 col-sm-5 col-8">
                            <form className="me-3">
                                <div className="input-group w-100"> <input type="text" className="form-control" placeholder="Search" /> <button className="input-group-text btn btn-primary"> <i
                                            className="bi-search"></i> </button> </div>
                            </form>
                        </div>
                        <div className="col-lg-5 col-sm-4 col-12">
                            <div className="float-md-end">
                                <NavLink to="/wishlist" className="btn btn-light">
                                    <i class="bi-heart me-1"></i> Wishlist 
                                </NavLink>
                                <NavLink data-bs-toggle="offcanvas" to="#offcanvas_cart" className="btn btn-light"> <i className="bi-basket me-1"></i> My cart </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <nav className="navbar navbar-light bg-white navbar-expand-lg border-bottom">
                <div className="container">
                    <button className="navbar-toggler border" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbar_main6" aria-expanded="false" aria-label="Toggle navigation"> <span
                            className="navbar-toggler-icon"> </span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbar_main6">
                        <ul className="navbar-nav">
                            <li className="nav-item"> <NavLink className="nav-link ps-0" to="/"> Home </NavLink> </li>
                            <li className="nav-item"> <NavLink className="nav-link" to="/about">About</NavLink> </li>
                            <li className="nav-item"> <NavLink className="nav-link" to="/service">Services</NavLink> </li>
                            <li className="nav-item"> <NavLink className="nav-link" to="/shop">Shop</NavLink> </li>
                            <li className="nav-item"> <NavLink className="nav-link" to="/contact">Contact</NavLink> </li>
                            <li className="nav-item"> <NavLink className="nav-link" to="/blog">Blog</NavLink> </li>
                        </ul>

                        <ul className="navbar-nav ms-auto">
                            <li className="nav-item"> <NavLink className="nav-link" to="/signin">Sign in </NavLink> </li>
                            <li className="nav-item"> <NavLink className="nav-link" to="/signup">Register </NavLink> </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>


    </>)
};

export default Navbar;