import web from "../src/img/blog-1.jpg";
import app from "../src/img/blog-2.jpg";
import ios from "../src/img/blog-3.jpg";
import digital from "../src/img/blog-4.jpg";

const Bdata = [
    {
        imgsrc: web,
        title: "Web Development",
        content: "When you enter into any new area of science, you almost reach.",
        date: "20-June-2022",
        links: "http://localhost:3000/service"
    },
    {
        imgsrc: app,
        title: "App Development",
        content: "When you enter into any new area of science, you almost reach.",
        date: "23-May-2022",
        links: "http://localhost:3000/service"
    },
    {
        imgsrc: ios,
        title: "IOS Development",
        content: "When you enter into any new area of science, you almost reach.",
        date: "10-Aug-2022",
        links: "http://localhost:3000/service"
    },
    {
        imgsrc: digital,
        title: "Digital Marketing",
        content: "When you enter into any new area of science, you almost reach.",
        date: "11-May-2022",
        links: "http://localhost:3000/service"
    },
];

export default Bdata;