import React, {Component} from "react";
import Slider from "react-slick";

export default class SimpleSlider extends Component {
    render() {
        const settings = {
            dots: true,
            infinite: true,
            loop: true,
            nav: true,
            lazyLoad: true,
            slidesToScroll: 1,
            slidesToShow: 1,
            autoplay: true,
            speed: 1000,
            autoplaySpeed: 2000,
        };
        return(<>

            <section class="bg-white border-bottom">
                <div class="container">
                    <Slider {...settings}>
                        <div>
                            <div class="row align-items-center">
                                <div class="col-lg-6 order-lg-2">
                                    <article class="my-5 ms-lg-5">
                                        <h2 class="display-4">New products &amp; <br /> brands in our shop</h2>
                                        <p class="lead">Trendy Products, Factory Prices, Excellent Service</p>
                                        <a href="/" class="btn btn-primary btn-lg"> Discover </a>
                                        <a href="/" class="btn btn-light btn-lg"> Learn more </a>
                                    </article>
                                </div>
                                <div class="col-lg-6 order-lg-1">
                                    <img src="../img/intro-item1.jpg" alt="slider" class="mw-100" height="500" />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="row align-items-center">
                                <div class="col-lg-6 order-lg-2">
                                    <article class="my-5 ms-lg-5">
                                        <h2 class="display-4">2 New products &amp; <br /> brands in our shop</h2>
                                        <p class="lead">Second Products Description here.</p>
                                        <a href="/" class="btn btn-primary btn-lg"> Discover </a>
                                    </article>
                                </div>
                                <div class="col-lg-6 order-lg-1">
                                    <img src="../img/intro-item1.jpg" alt="slider" class="mw-100" height="500" />
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </section>

        </>);
    }
}