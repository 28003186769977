import React from "react";

const Whychoose =(props) => {
    return(<>
            <section className="bg-light padding-y">
                <div className="container">

                    <header className="section-heading mt-2 mb-5">
                        <h3 className="section-title">{props.heading}</h3>
                    </header>

                    <div className="row mb-4">
                        <div className="col-lg-4 col-md-6">
                            <figure className="itemside align-items-center mb-4">
                                <div className="aside">
                                    <span className="rounded-circle shadow-sm text-primary icon-lg bg-white">
                                        <i className="bi-cash"></i>
                                    </span>
                                </div>
                                <figcaption className="info">
                                    <h6 className="title">{props.itemheading1}</h6>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmor </p>
                                </figcaption>
                            </figure>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <figure className="itemside align-items-center  mb-4">
                                <div className="aside">
                                    <span className="rounded-circle shadow-sm text-primary icon-lg bg-white">
                                        <i className="bi-star"></i>
                                    </span>
                                </div>
                                <figcaption className="info">
                                    <h6 className="title">{props.itemheading2}</h6>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmor </p>
                                </figcaption>
                            </figure>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <figure className="itemside align-items-center  mb-4">
                                <div className="aside">
                                    <span className="rounded-circle shadow-sm text-primary icon-lg bg-white">
                                        <i className="bi-truck"></i>
                                    </span>
                                </div>
                                <figcaption className="info">
                                    <h6 className="title">Worldwide shipping</h6>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmor </p>
                                </figcaption>
                            </figure>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <figure className="itemside align-items-center  mb-4">
                                <div className="aside">
                                    <span className="rounded-circle shadow-sm text-primary icon-lg bg-white">
                                        <i className="bi-people"></i>
                                    </span>
                                </div>
                                <figcaption className="info">
                                    <h6 className="title">Customer satisfaction</h6>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmor </p>
                                </figcaption>
                            </figure>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <figure className="itemside align-items-center mb-4">
                                <div className="aside">
                                    <span className="rounded-circle shadow-sm text-primary icon-lg bg-white">
                                        <i className="bi-hand-thumbs-up"></i>
                                    </span>
                                </div>
                                <figcaption className="info">
                                    <h6 className="title">Happy customers</h6>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmor </p>
                                </figcaption>
                            </figure>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <figure className="itemside align-items-center mb-4">
                                <div className="aside">
                                    <span className="rounded-circle shadow-sm text-primary icon-lg bg-white">
                                        <i className="bi-box"></i>
                                    </span>
                                </div>
                                <figcaption className="info">
                                    <h6 className="title">Thousand items</h6>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmor </p>
                                </figcaption>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>
    </>)
};
export default Whychoose;