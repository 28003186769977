import React from "react";
import { NavLink } from "react-router-dom";

const Service =() => {
    return(<>
        
        <section class="bg-primary shadow py-5">
            <div class="container align-items-center justify-content-center d-flex">
                <article class="text-center">
                    <h1 class="display-4 text-white">Services</h1>
                </article>
            </div>
        </section>

        <section className="padding-y bg-light">
            <div className="container">
                <div className="row gy-3 mb-5">
                    <div className="col-lg-4 col-md-6">
                        <article className="card bg-dark"> <img src="../img/banner1.jpg" height="160" className="card-img opacity" alt="img" />
                            <div className="card-img-overlay">
                                <h5 className="mb-0 text-white">Apple Devices</h5>
                                <p className="card-text text-white">This is a wider card with a text below</p> <a href="/ " className="btn btn-light">Discover</a>
                            </div>
                        </article>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <article className="card bg-dark"> <img src="../img/banner1.jpg" height="160" className="card-img opacity" alt="" />
                            <div className="card-img-overlay">
                                <h5 className="mb-0 text-white">Apple Devices</h5>
                                <p className="card-text text-white">This is a wider card with a text below</p>
                                <a href="/ " className="btn btn-light">Discover</a>
                            </div>
                        </article>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <article className="card bg-dark"> <img src="../img/banner1.jpg" height="160" className="card-img opacity" alt="" />
                            <div className="card-img-overlay">
                                <h5 className="mb-0 text-white">Apple Devices</h5>
                                <p className="card-text text-white">This is a wider card with a text below</p> <a href="/ " className="btn btn-light">Discover</a>
                            </div>
                        </article>
                    </div>
                </div>

                <div className="row gy-3 mb-5">
                    <div className="col-lg-4 col-md-6">
                        <article className="card card-banner" style={{ height : '220px', backgroundImage: `url("../img/banner1.jpg")` }} >
                            <div className="card-body caption">
                                <h5 className="card-title text-white">Men</h5>
                                <p>No matter how far along you are in your sophistication as an amateur astronomer, there is always one.</p>
                                <a href="/" className="btn btn-primary"> View more </a>
                            </div>
                        </article>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <article className="card card-banner" style={{ height : '220px', backgroundImage: `url("../img/banner1.jpg")` }} >
                            <div className="card-body caption">
                                <h5 className="card-title text-white">Women</h5>
                                <p>No matter how far along you are in your sophistication as an amateur astronomer, there is always one.</p>
                                <a href="/" className="btn btn-primary"> View more </a>
                            </div>
                        </article>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <article className="card card-banner" style={{ height : '220px', backgroundImage: `url("../img/banner1.jpg")` }} >
                            <div className="card-img-overlay caption">
                                <h5 className="card-title text-white">Kids</h5>
                                <p>No matter how far along you are in your sophistication as an amateur astronomer, there is always one.</p>
                                <a href="/" className="btn btn-primary"> View more </a>
                            </div>
                        </article>
                    </div>
                </div>
                
                <div className="row gy-3 mb-5">
                    <div className="col-lg-4 col-md-6">
                        <article className="card-banner" style={{ height : '220px', backgroundImage: `url("../img/banner1.jpg")` }} >
                            <div className="text-bottom">
                                <h5 className="mb-0 text-white"> Men's summer collection <br /> Get 80% offer </h5>
                            </div>
                        </article>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <article className="card-banner" style={{ height : '220px', backgroundImage: `url("../img/banner1.jpg")` }} >
                            <div className="caption p-3 bottom">
                                <h5 className="card-title text-white">Watches</h5>
                                <p className="mb-0">No matter how far along you are in your sophistication as an amateur.</p>
                            </div>
                        </article>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <article className="card-banner" style={{ height : '220px', backgroundImage: `url("../img/banner1.jpg")` }} >
                            <div className="caption p-3 mt-auto m-4 w-100">
                                <h5 className="card-title text-white">Camera &amp; Photo</h5>
                                <p className="mb-0">No matter how far along you are in your sophistication as an amateur.</p>
                            </div>
                        </article>
                    </div>
                </div>
                
                <div className="row gy-3 mb-5">
                    <div className="col-lg-8">
                        <div className="card-banner overlay-gradient" style={{ backgroundImage: `url("../img/banner1.jpg")` }} >
                            <div className="card-img-overlay">
                                <h3 className="card-title text-white">T-Shirts &amp; Jackets</h3>
                                <p className="card-text text-white"> No matter how far along you are in your sophistication as an amateur astronomer, there is always one.</p> <NavLink to="/" className="btn btn-warning">Learn more</NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="card-banner overlay-gradient" style={{ backgroundImage: `url("../img/banner1.jpg")` }} >
                            <div className="card-img-overlay">
                                <h5 className="card-title text-white">Best products</h5>
                                <p className="card-text text-white">No matter how far along you are in your sophistication as an amateur astronomer, there is always one.</p>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="row gy-3 mb-5">
                    <div className="col-lg-4 col-md-6">
                        <div className="card card-banner">
                            <div className="p-4" style={{ width: '70%'}}>
                                <h5 className="title">iPad Pro</h5>
                                <p className="mb-0">Some quick example text to build on the card title and make up the bulk info</p>
                            </div> <img src="../img/banner-item1.jpg" height="150" className="img-bg" alt="" />
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="card card-banner">
                            <div className="p-4" style={{ width: '70%'}}>
                                <h5 className="title">Smart Watch</h5>
                                <p className="mb-0">Some quick example text to build on the card title and make up the bulk info</p>
                            </div> <img src="../img/banner-item2.jpg" height="150" className="img-bg" alt="" />
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="card card-banner">
                            <div className="p-4" style={{ width: '70%'}}>
                                <h5 className="title">Great drones</h5>
                                <p className="mb-0">Some quick example text to build on the card title and make up the bulk info</p>
                            </div> <img src="../img/banner-item3.jpg" height="150" className="img-bg" alt="" />
                        </div>
                    </div>
                </div>
                
                <article className="card p-4 bg-primary">
                    <div className="row align-items-center">
                        <div className="col">
                            <h4 className="mb-0 text-white">Banner sample heading name</h4>
                            <p className="mb-0 text-white-50">Trendy products and text to build on the card title</p>
                        </div>
                        <div className="col-auto"> <NavLink to="/" className="btn btn-warning">Discover</NavLink> </div>
                    </div>
                </article>
            </div>
        </section>

    </>)
};

export default Service;