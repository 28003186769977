import React from "react";
import Slider from './Slider';
import { NavLink } from "react-router-dom";
import Whychoose from "./Whychoose";
import Blog from "./Blog";
import Bdata from "./Bdata";

const Home =() => {
    return(<>

        <Slider />
        
        <section className="padding-y">
            <div className="container">

                <header className="section-heading">
                    <h3 className="section-title">New products</h3>
                </header>

                <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <figure className="card card-product-grid">
                            <div className="img-wrap">
                                <img src="../src/img/1.jpg" alt="" />
                            </div>
                            <figcaption className="info-wrap border-top">
                                <div className="price-wrap">
                                    <span className="price">$790.50</span>
                                </div>
                                <p className="title mb-2">GoPro HERO6 4K Action Camera - Black</p>

                                <NavLink to="/" className="btn btn-primary">Add to cart</NavLink>
                                <NavLink to="/" className="btn btn-light btn-icon"> <i className="fa fa-heart"></i> </NavLink>
                            </figcaption>
                        </figure>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <figure className="card card-product-grid">
                            <div className="img-wrap">
                                <img src="../src/img/2.jpg" alt="" />
                            </div>
                            <figcaption className="info-wrap border-top">
                                <div className="price-wrap">
                                    <span className="price">$790.50</span>
                                </div>
                                <p className="title mb-2">GoPro HERO6 4K Action Camera - Black</p>

                                <NavLink to="/" className="btn btn-primary">Add to cart</NavLink>
                                <NavLink to="/" className="btn btn-light btn-icon"> <i className="fa fa-heart"></i> </NavLink>
                            </figcaption>
                        </figure>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <figure className="card card-product-grid">
                            <div className="img-wrap">
                                <img src="../img/3.jpg" alt="" />
                            </div>
                            <figcaption className="info-wrap border-top">
                                <div className="price-wrap">
                                    <span className="price">$790.50</span>
                                </div>
                                <p className="title mb-2">GoPro HERO6 4K Action Camera - Black</p>

                                <NavLink to="/" className="btn btn-primary">Add to cart</NavLink>
                                <NavLink to="/" className="btn btn-light btn-icon"> <i className="fa fa-heart"></i> </NavLink>
                            </figcaption>
                        </figure>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <figure className="card card-product-grid">
                            <div className="img-wrap">
                                <img src="../img/4.jpg" alt="" />
                            </div>
                            <figcaption className="info-wrap border-top">
                                <div className="price-wrap">
                                    <span className="price">$790.50</span>
                                </div>
                                <p className="title mb-2">GoPro HERO6 4K Action Camera - Black</p>

                                <NavLink to="/" className="btn btn-primary">Add to cart</NavLink>
                                <NavLink to="/" className="btn btn-light btn-icon"> <i className="fa fa-heart"></i> </NavLink>
                            </figcaption>
                        </figure>
                    </div>

                </div> 
            </div>
        </section>

        <Whychoose
            heading="Why choose us for homepage" 
            itemheading1="This is test Heading for Home"
            itemheading2="Best Quality"
        />

        <section className="padding-y">
            <div className="container">

                <header className="section-heading">
                    <h3 className="section-title">Blog posts</h3>
                </header>

                <div className="row gy-3">
                    {
                        Bdata.map((val, ind) => {
                            return <Blog
                                key={ind}
                                imgsrc={val.imgsrc}
                                title={val.title}
                                content={val.content}
                                date={val.date}
                                link={val.links}
                            />
                        })
                    }
                </div>

            </div>
        </section>

        <section className="bg-white shadow">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-xl-6 col-xxl-5">
                        <article className="my-5">
                            <h1 className="display-4">Best products &amp; <br /> brands in our store</h1>
                            <p className="lead">Trendy Products, Factory Prices, Excellent Service</p> <a href="/" className="btn btn-primary btn-lg"> Discover </a>
                            <a href="/" className="btn btn-light btn-lg"> Learn more </a>
                        </article>
                    </div>
                    <div className="col-lg-6 col-xl-6 col-xxl-7">
                        <img src="../img/intro-item2.jpg" alt="home" className="mw-100" />
                    </div>
                </div>
            </div>
        </section>

    </>)
};
export default Home;