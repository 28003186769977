import React from "react";

const Shop =() => {
    return (<>
    
            <section class="bg-primary shadow py-5">
                <div class="container align-items-center justify-content-center d-flex">
                    <article class="text-center">
                        <h1 class="display-4 text-white">Online Store</h1>
                    </article>
                </div>
            </section>

    </>)
};
export default Shop;